import { FetchOptions } from 'ohmyfetch'

export const useFetchAuth = async (url: string, opts?: FetchOptions) => {
  const config = useRuntimeConfig()
  const { data: session } = useSessionState()
  let signOut: any = null

  if (!process.server) {
    const sess = useSession()
    signOut = sess.signOut
  }
  const headers: HeadersInit = {
    ...(opts?.headers || {}),
    ...(session.value.user.accessToken && {
      Authorization: `Bearer ${session.value.user.accessToken}`,
    }),
  }

  let apiBase: any = ''
  if (process.server) apiBase = config.internalApiUrl
  else apiBase = config.public.baseUrl

  try {
    const response = await $fetch(`${apiBase}${url}`, {
      ...opts,
      headers,
    })
    return response
  } catch (error) {
    if (error.status === 401) {
      if (!process.server) signOut()
    } else if (error.status === 403) navigateTo('/403')
    console.error('error', error.status)
    throw createError({
      data: (error as FetchError).response,
    })
  }
}
